// @codekit-prepend "plugins/customSelect.js", "plugins/plugins.js", "plugins/tinynav.js", "plugins/retina.js";

$(function() {
    $(".nav-top").tinyNav({
        active: 'current_page_item'
    });

    $(".nav-top").hide();

    $('.vragen').addClass('js');

    $('.vragen dd').hide();
    $('.vragen dt').click(function() {
        $('.vragen dd').hide();
        $(this).toggleClass('active');
        $(this).next().toggle();
    });

    $('.slider').innerFade({
        speed: 2000,
        timeout: 6000
    });
});

$(document).ready(function(){
    $('.tinynav').customSelect();
});